import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Home.css';

import HomeInsights from './HomeInsights';
import Heading from './Heading';
import ServicesCore from './ServicesCore';
import Technologies from './Technologies';
import Projects from './Projects/Projects';


const Home = () => {

    const title = "Our Core Services"
    const description = "Our core services at include expert web and mobile application development, along with seamless site hosting and management solutions."

    const textContainer = useRef(null);
    const index = useRef(0);
    const removing = useRef(false);
    const currentTextIndex = useRef(0);
    const typeTextTimeout = useRef(null);
    const removeTextTimeout = useRef(null);
    const [showCursor, setShowCursor] = useState(true);

    const texts = [
        "Web Application Development",
        "E-commerce Solutions",
        "Hosting Solutions"
    ];

    useEffect(() => {
        const typeText = () => {
            const text = texts[currentTextIndex.current];

            if (!removing.current) {
                textContainer.current.textContent += text[index.current];
                index.current++;
                if (index.current < text.length) {
                    typeTextTimeout.current = setTimeout(typeText, 120);
                } else {
                    removeTextTimeout.current = setTimeout(removeText, 1200);
                }
            }
        };

        const removeText = () => {
            removing.current = true;
            const currentText = textContainer.current.textContent;
            textContainer.current.textContent = currentText.slice(0, -1);
            if (textContainer.current.textContent.length > 0) {
                removeTextTimeout.current = setTimeout(removeText, 50);
            } else {
                removing.current = false;
                index.current = 0;
                currentTextIndex.current = (currentTextIndex.current + 1) % texts.length;
                typeTextTimeout.current = setTimeout(typeText, 100);
            }
        };

        typeText();

        return () => {
            clearTimeout(typeTextTimeout.current);
            clearTimeout(removeTextTimeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowCursor((prevShowCursor) => !prevShowCursor);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="Home">
                <div className="content">
                    <div className="navigator"></div>
                    <div className="home-content center">
                        <h1>Creating solutions that drive digital success.</h1>
                        <div className="repeating">
                            <span className="typed-text" ref={textContainer}></span>
                            {showCursor && <span className="cursor">|</span>}
                        </div>
                        <p>We specialize in delivering top-tier software
                            development services. From crafting custom applications to
                            enhancing existing systems, our team of skilled developers
                            is dedicated to turning your vision into reality with
                            precision and innovation.</p>
                        <div className="home-btns">
                            <Link to={'/about'} className='shine button'> About Us </Link>
                            <Link to={'/contact-us'} className='shine button'>Contact</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Heading
                title="Featured Projects"
                description="Explore our latest projects showcasing innovative solutions and cutting-edge technology implementations."
            />
            <Projects />
            <Heading
                title={title}
                description={description}
            />
            <ServicesCore style={{ margin: '5px auto 100px auto' }} />
            <HomeInsights />
            <div className="tech-cover">
                <Technologies />
            </div>
            <div className="center">
                <Link to={'/services'} className='shine button partner'>Services</Link>
            </div>
        </>

    );
};

export default Home;
