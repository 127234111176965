import React from 'react';
import styles from './Legal.module.css';
import { useDocumentHead } from '../../../hooks/useDocumentHead';
import Footer from '../Footer/Footer';

const Terms = () => {
  useDocumentHead(
    'Terms of Service | Present Pro',
    'Present Pro terms of service and usage guidelines. Understand your rights and responsibilities while using our AI-powered presentation analysis and creation platform.',
    'noindex'
  );
  return (
    <><div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Terms of Service</h1>
          <p>Please read these terms carefully before using PresentProAI</p>
        </div>

        <section className={styles.section}>
          <h2>Acceptance of Terms</h2>
          <p>By accessing and using PresentProAI's presentation analysis services, you acknowledge and agree to be bound by these Terms of Service and all applicable laws and regulations.</p>
        </section>

        <section className={styles.section}>
          <h2>Service Description</h2>
          <p>PresentProAI provides advanced AI-powered presentation analysis and feedback services. We continuously improve our services and reserve the right to modify or enhance features to better serve our users.</p>
        </section>

        <section className={styles.section}>
          <h2>User Accounts</h2>
          <p>You are responsible for maintaining the security of your account credentials and all activities conducted under your account. We implement robust security measures but require your cooperation in protecting your account access.</p>
        </section>

        <section className={styles.section}>
          <h2>Content Ownership</h2>
          <p>You retain all rights to your presentation content and associated materials. By using our service, you grant PresentProAI a limited license to analyze and process your content for the purpose of providing feedback and analytics.</p>
        </section>

        <section className={styles.section}>
          <h2>Prohibited Activities</h2>
          <p>To maintain service quality and user safety, the following activities are strictly prohibited:</p>
          <ul>
            <li>Using the service for any unlawful purpose or violating applicable regulations</li>
            <li>Uploading content that infringes on intellectual property rights</li>
            <li>Sharing inappropriate, harmful, or discriminatory content</li>
            <li>Attempting to compromise system security or integrity</li>
            <li>Interfering with other users' access to the service</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2>Termination</h2>
          <p>We reserve the right to suspend or terminate access to our service immediately, without prior notice, for conduct that violates these Terms of Service or poses a risk to our service or other users.</p>
        </section>

        <section className={styles.section}>
          <h2>Disclaimer</h2>
          <p>While we strive to provide accurate and helpful presentation analysis, our service is provided "as is" without warranties of any kind, either express or implied. We continuously work to improve our AI models but cannot guarantee specific outcomes.</p>
        </section>

        <section className={`${styles.section} ${styles.contactSection}`}>
          <h2>Contact Information</h2>
          <p>For questions about these terms or to report violations, please contact our support team at saadaryf@gmail.com</p>
        </section>
      </div>
    </div><Footer /></>
  );
};

export default Terms;