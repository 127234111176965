import React from 'react';
import styles from './FAQSection.module.css';

const FAQSection = () => {
    return (
        <section id="faq" className={styles.faqSection}>
            <h2>Frequently Asked Questions</h2>
            <div className={styles.faqGrid}>
                <div className={styles.faqItem}>
                    <h3>How does the AI analysis work?</h3>
                    <p>Our AI provides real-time feedback on your filler words and other key speech elements. Once your presentation is complete, you’ll receive a comprehensive analysis with actionable insights to help you improve.</p>
                </div>
                <div className={styles.faqItem}>
                    <h3>Can I use PresentPro offline?</h3>
                    <p>While an internet connection is required for AI analysis, you can download your recordings for offline review.</p>
                </div>
                <div className={styles.faqItem}>
                    <h3>Is my data secure?</h3>
                    <p>Yes, we use enterprise-grade encryption and follow strict privacy guidelines to ensure your data is always secure.</p>
                </div>
                <div className={styles.faqItem}>
                    <h3>Can I switch plans anytime?</h3>
                    <p>Yes, you can upgrade, downgrade, or cancel your subscription at any time without any hidden fees.</p>
                </div>
            </div>
        </section>
    );
};

export default FAQSection;