import React from 'react';
import styles from './Legal.module.css';
import { useDocumentHead } from '../../../hooks/useDocumentHead';
import Footer from '../Footer/Footer';

const Privacy = () => {
  useDocumentHead(
    'Privacy Policy | Present Pro',
    'Learn how Present Pro protects your data and privacy. Understand our data collection, storage, usage, and your rights regarding your personal information.',
    'noindex'
  );
  return (
    <><div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Privacy Policy</h1>
          <p>Learn how PresentProAI handles and protects your information</p>
        </div>

        <section className={styles.section}>
          <h2>Information We Collect</h2>
          <p>We collect and process the following information to provide you with the best presentation analysis experience:</p>
          <ul>
            <li>Account information (name, email, password)</li>
            <li>Presentation recordings and transcripts</li>
            <li>Usage data and analytics</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2>How We Use Your Information</h2>
          <p>Your information helps us deliver and improve our AI-powered presentation analysis services:</p>
          <ul>
            <li>Provide and improve our presentation analysis services</li>
            <li>Generate detailed performance insights and feedback</li>
            <li>Track your progress and create comprehensive reports</li>
            <li>Send important service updates and notifications</li>
            <li>Enhance our AI models for better analysis accuracy</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2>Data Storage and Security</h2>
          <p>We implement industry-standard security measures to protect your personal information. All data is encrypted in transit and at rest using enterprise-grade encryption protocols.</p>
        </section>

        <section className={styles.section}>
          <h2>Data Sharing</h2>
          <p>We maintain strict data privacy standards and do not sell or share your personal information with third parties except as necessary to provide our services or as required by law.</p>
        </section>

        <section className={styles.section}>
          <h2>Your Rights</h2>
          <p>We respect your data privacy rights and provide you with control over your information:</p>
          <ul>
            <li>Access and download your complete presentation history and personal data</li>
            <li>Correct or update any inaccurate information in your profile</li>
            <li>Request deletion of your account and associated data</li>
            <li>Export your presentation analytics and feedback history</li>
            <li>Control your communication preferences and data usage settings</li>
          </ul>
        </section>

        <section className={`${styles.section} ${styles.contactSection}`}>
          <h2>Contact Us</h2>
          <p>If you have any questions about our privacy practices or would like to exercise your data rights, please contact our privacy team at saadaryf@gmail.com</p>
        </section>
      </div>
    </div><Footer /></>
  );
};

export default Privacy;