import React from 'react';
import { CheckCircle2 } from 'lucide-react';
import styles from './PricingSection.module.css';

const PricingSection = () => {
  const renderFeatures = (planType) => {
    const features = {
      free: {
        title: 'Basic',
        price: '0',
        features: [
          '3 Live Practice Sessions / 6 hours',
          '20 AI Coach Voice Messages / 6 hours',
          '50 AI Presentation Templates / month',
          'Recording time upto: 1 minute',
          'AI Coach Text Messages: Unlimited'
        ]
      },
      student: {
        title: 'Student',
        price: '2.99',
        yearlyPrice: '1.99',
        features: [
          '7 Live Practice Sessions / 6 hours',
          '75 AI Coach Voice Messages / 6 hours',
          '450 AI Presentation Templates / month',
          'Recording time upto: 4 minutes',
          'AI Coach Text Messages: Unlimited',
          'Expert Video Consultations',
          'Keep Your AI Generated Slides Private'
        ]
      },
      pro: {
        title: 'Professional',
        price: '14.99',
        yearlyPrice: '8.99',
        features: [
          '15 Live Practice Sessions / 6 hours',
          '150 AI Coach Voice Messages / 6 hours',
          '1050 AI Presentation Templates / month',
          'Recording time upto: 10 minutes',
          'AI Coach Text Messages: Unlimited',
          'Expert Video Consultations',
          'Keep Your AI Generated Slides Private'
        ]
      }
    };

    return features[planType];
  }

  return (
    <section id="pricing" className={styles.pricingSection}>
      <h2>Simple, Transparent Pricing</h2>
      <p className={styles.subtitle}>Choose the plan that best fits your needs</p>

      <div className={styles.pricingCards}>
        {/* Basic Plan */}
        <div className={styles.pricingCard}>
          <h3>{renderFeatures('free').title}</h3>
          <div className={styles.price}>
            ${renderFeatures('free').price}
            <span>/month</span>
          </div>
          <ul>
            {renderFeatures('free').features.map((feature, index) => (
              <li key={index}>
                <CheckCircle2 size={16} />
                {feature}
              </li>
            ))}
          </ul>
          <button className={styles.priceButton}>
            Get Started
          </button>
        </div>

        {/* Student Plan */}
        <div className={`${styles.pricingCard} ${styles.featured}`}>
          <div className={styles.popularBadge}>Student Discount</div>
          <h3>{renderFeatures('student').title}</h3>
          <div className={styles.priceContainer}>
            <div className={styles.price}>
              ${renderFeatures('student').price}
              <span>/month</span>
            </div>
            <div className={styles.yearlyPrice}>
              ${renderFeatures('student').yearlyPrice}/month with annual billing
              <span className={styles.savingsBadge}>Save ~33%</span>
            </div>
          </div>
          <ul>
            {renderFeatures('student').features.map((feature, index) => (
              <li key={index}>
                <CheckCircle2 size={16} />
                {feature}
              </li>
            ))}
          </ul>
          <button className={styles.priceButton}>
            Get This Plan
          </button>
        </div>

        {/* Professional Plan */}
        <div className={styles.pricingCard}>
          <h3>{renderFeatures('pro').title}</h3>
          <div className={styles.priceContainer}>
            <div className={styles.price}>
              ${renderFeatures('pro').price}
              <span>/month</span>
            </div>
            <div className={styles.yearlyPrice}>
              ${renderFeatures('pro').yearlyPrice}/month with annual billing
              <span className={styles.savingsBadge}>Save ~40%</span>
            </div>
          </div>
          <ul>
            {renderFeatures('pro').features.map((feature, index) => (
              <li key={index}>
                <CheckCircle2 size={16} />
                {feature}
              </li>
            ))}
          </ul>
          <button className={styles.priceButton}>
            Go Professional
          </button>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;