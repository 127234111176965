import React from 'react';
import { Mail } from 'lucide-react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.footerMain}>
                    <div className={styles.footerBrand}>
                        <h3>Present<span>Pro</span></h3>
                        <p>Advanced AI-powered presentation analytics and coaching for professionals.</p>
                        <div className={styles.socialLinks}>
                            <a href="mailto:info@devsaura.com" className={styles.socialLink} aria-label="Email">
                                <Mail size={18} />
                            </a>
                        </div>
                    </div>

                    <div className={styles.footerLinks}>
                        <div className={styles.linkGroup}>
                            <h4>Important Links</h4>
                            <ul>
                                <li><Link to="/PresentProAi" >Home</Link></li>
                            </ul>
                            <ul>
                                <li><a href="https://speech.devsaura.com" target='_blank' rel='noopener noreferrer' >Open App</a></li>
                            </ul>
                        </div>

                        <div className={styles.linkGroup}>
                            <h4>Discover More</h4>
                            <ul>
                                <li><a href="https://rangrezai.com" target='_blank' rel='noopener noreferrer' >Rangrez AI</a></li>
                            </ul>
                        </div>

                        <div className={styles.linkGroup}>
                            <h4>Resources</h4>
                            <ul>
                                <li><a href="https://speech.devsaura.com/docs">Documentation</a></li>
                            </ul>
                        </div>

                        <div className={styles.linkGroup}>
                            <h4>Legal</h4>
                            <ul>
                                <li><Link to="/PresentProAi/privacy">Privacy</Link></li>
                                <li><Link to="/PresentProAi/terms">Terms</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={styles.footerBottom}>
                    <p>&copy; {currentYear} PresentPro. All rights reserved.</p>
                    <div className={styles.footerMeta}>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;