import React from 'react';
import './auth.css';

const PresentProPrivacy = () => {
    return (
        <div className='legal-container'>
            <div className="legal-content">
                <h1>Privacy Policy</h1>
                <div className="legal-section">
                    <h2>1. Information Collection</h2>
                    <p>We collect information you provide directly to us, including name, email, and company details. We also automatically collect certain information about your device.</p>
                </div>

                <div className="legal-section">
                    <h2>2. Use of Information</h2>
                    <p>We use collected information to provide and improve our services, communicate with you, and ensure security of our platform.</p>
                </div>

                <div className="legal-section">
                    <h2>3. Information Sharing</h2>
                    <p>We do not sell your personal information. We may share your information with service providers who assist in our operations.</p>
                </div>

                <div className="legal-section">
                    <h2>4. Data Security</h2>
                    <p>We implement appropriate security measures to protect your personal information from unauthorized access or disclosure.</p>
                </div>

                <div className="legal-section">
                    <h2>5. Your Rights</h2>
                    <p>You have the right to access, correct, or delete your personal information. Contact us to exercise these rights.</p>
                </div>
            </div>
        </div>
    );
};

export default PresentProPrivacy;