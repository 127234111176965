import React from 'react'
import '../css/Footer.css'

import Top from './Footer/Top'
import Mid from './Footer/Mid'
import Bottom from './Footer/Bottom'
import { useLocation } from 'react-router-dom'

const Footer = () => {
  const location = useLocation();
  if (location.pathname.startsWith('/PresentProAi')) {
    return null;
  }
  return (
    <footer>
      <Top />
      <Mid />
      <Bottom />
    </footer>
  )
}

export default Footer
