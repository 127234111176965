import React, { useState, useEffect } from 'react';
import { ChevronRight, Play } from 'lucide-react';
import styles from './HeroSection.module.css';

const HeroSection = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    { src: '/images/PresentPro/images/recording.png', alt: 'Recording Interface' },
    { src: '/images/PresentPro/images/feedback.png', alt: 'Feedback Screen' },
    { src: '/images/PresentPro/images/coaching.png', alt: 'Coaching Screen' },
    { src: '/images/PresentPro/images/analytics.png', alt: 'Analytics Dashboard' }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
    // eslint-disable-next-line 
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentImage(index);
  };

  return (
    <section id="home" className={styles.heroSection}>
      <div className={styles.backgroundSplit}>
        <div className={styles.leftBackground}></div>
        <div className={styles.rightBackground}></div>
      </div>
      <div className={styles.heroContent}>
        <div className={styles.heroText}>
          <h1>Master Your Presentations with AI-Powered Feedback</h1>
          <p>
            Transform your presentation skills with real-time AI analysis,
            personalized coaching, and detailed analytics. Practice, improve,
            and gain confidence in your public speaking journey.
          </p>
          <div className={styles.heroButtons}>
            <a href="https://speech.devsaura.com" className={styles.primaryButton}>
              Get Started Free <ChevronRight size={20} />
            </a>
            <button className={styles.demoButton}>
              <Play size={20} /> Watch Demo
            </button>
          </div>
        </div>
        <div className={styles.heroImages}>
          <div className={styles.carouselContainer}>
            <div className={styles.carousel}>
              {images.map((image, index) => (
                <img
                  key={image.src}
                  src={image.src}
                  alt={image.alt}
                  className={`${styles.carouselImage} ${index === currentImage ? styles.active : ''
                    }`}
                />
              ))}
            </div>
            <div className={styles.indicatorsContainer}>
              <div className={styles.indicators}>
                {images.map((_, index) => (
                  <button
                    key={index}
                    className={`${styles.indicator} ${index === currentImage ? styles.activeIndicator : ''
                      }`}
                    onClick={() => handleIndicatorClick(index)}
                    aria-label={`Go to slide ${index + 1}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;