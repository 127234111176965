import React from 'react';
import './auth.css';

const PresentProTerms = () => {
    return (
        <div className='legal-container'>
            <div className="legal-content">
                <h1>Terms and Conditions</h1>
                <div className="legal-section">
                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing and using DevsAura's services, you agree to be bound by these terms and conditions.</p>
                </div>
                
                <div className="legal-section">
                    <h2>2. Services Description</h2>
                    <p>DevsAura provides web development, e-commerce solutions, and hosting assistance services. We reserve the right to modify or discontinue any service without notice.</p>
                </div>
                
                <div className="legal-section">
                    <h2>3. User Obligations</h2>
                    <p>Users must provide accurate information and maintain the confidentiality of their account credentials. You are responsible for all activities under your account.</p>
                </div>
                
                <div className="legal-section">
                    <h2>4. Intellectual Property</h2>
                    <p>All content, trademarks, and services provided by DevsAura are protected by intellectual property laws. Users may not copy, modify, or distribute our content without permission.</p>
                </div>
                
                <div className="legal-section">
                    <h2>5. Limitation of Liability</h2>
                    <p>DevsAura shall not be liable for any indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.</p>
                </div>
            </div>
        </div>
    );
};

export default PresentProTerms;