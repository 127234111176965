import React from 'react'
import '../../css/footer/Bottom.css'
import { Link } from 'react-router-dom'

const Bottom = () => {
    return (
        <div className='Bottom center'>
            <p>&copy; 2024 Devsaura. All rights reserved.</p>
            <p>
                <Link to="/privacy">Privacy Policy</Link> |  <Link to="/terms">Terms of Service</Link>
            </p>
        </div>
    )
}

export default Bottom
