import React from 'react';
import styles from './Projects.module.css';
import { Link } from 'react-router-dom';

const Projects = () => {
    const projects = [
        {
            title: "Rangrez AI",
            description: "An AI-powered creative platform that transforms the design process. Users can generate unique designs, save favorites, and engage with a community of designers through features like likes, follows, and community chat.",
            tags: ["Free AI powered design generation", "Design Customization", "Growing design community"],
            link: "https://rangrezai.com",
            isExternal: true
        },
        {
            title: "PresentPro AI",
            description: "An innovative platform combining AI and public speaking enhancement. Offers real-time feedback on presentations, AI-powered slide creation, and comprehensive delivery analysis including wpm, fillers and profanity.",
            tags: ["Real Time Feeback & AI Analysis", "Instant AI Slides Generation", "Personalized AI coaching", "Expert video consultations"],
            link: "/PresentProAi",
            isExternal: false
        }
    ];

    return (
        <div className={styles.projectsContainer}>
            <div className={styles.projectsGrid}>
                {projects.map((project, index) => (
                    <Link
                        to={project.link}
                        className={styles.projectCard}
                        key={index}
                        {...(project.isExternal ? {
                            target: "_blank",
                            rel: "noopener noreferrer"
                        } : {})}
                    >
                        <div className={styles.projectContent}>
                            <h3>{project.title}</h3>
                            <p>{project.description}</p>
                            <div className={styles.tags}>
                                {project.tags.map((tag, idx) => (
                                    <span key={idx} className={styles.tag}>{tag}</span>
                                ))}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Projects;