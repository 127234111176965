import React, { useState, useEffect } from 'react';
import Header from '../Components/PresentProLanding/Header/Header'
import HeroSection from '../Components/PresentProLanding/HeroSection/HeroSection'
import FeaturesSection from '../Components/PresentProLanding/FeaturesSection/FeaturesSection'
import PricingSection from '../Components/PresentProLanding/PricingSection/PricingSection'
import FAQSection from '../Components/PresentProLanding/FAQSection/FAQSection'
import Footer from '../Components/PresentProLanding/Footer/Footer'
import { useDocumentHead } from '../hooks/useDocumentHead'

const PresentProLanding = () => {
    useDocumentHead(
        'PresentProAi | Enhance Your Presentation Skills with AI',
        'Start your journey to better presentations with personalized feedback, instant slides, AI coaching, and progress tracking.',
        'index, follow'
    );
    const [activeSection, setActiveSection] = useState('home');

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            sections.forEach(section => {
                const top = section.offsetTop - 100;
                const height = section.offsetHeight;
                if (window.scrollY >= top && window.scrollY < top + height) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div>
            <Header activeSection={activeSection} />
            <HeroSection />
            <FeaturesSection />
            <PricingSection />
            <FAQSection />
            <Footer />
        </div>
    );
};

export default PresentProLanding;