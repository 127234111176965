import React, { useState, useEffect, useRef } from 'react';
import { Wand2, FileOutput, Brain } from 'lucide-react';
import styles from './FeaturesSection.module.css';

const FEATURES = [
  {
    id: 1,
    icon: Brain,
    title: "Real-time AI Analysis",
    description: "Get instant feedback on filler words while speaking and receive detailed AI-powered analysis upon completion",
    preview: "/images/PresentPro/gifs/recording.gif"
  },
  {
    id: 2,
    icon: Wand2,
    title: "Instant Slide Generation",
    description: "Simply enter your topic and let AI generate professional presentation slides instantly",
    preview: "/images/PresentPro/gifs/creating.gif"
  },
  {
    id: 3,
    icon: FileOutput,
    title: "Easy Edit & Export",
    description: "Preview and edit your slides seamlessly, then export to PowerPoint with just one click",
    preview: "/images/PresentPro/gifs/editing.gif"
  }
];

const FeaturesSection = () => {
  const [activeFeature, setActiveFeature] = useState(FEATURES[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentImage, setCurrentImage] = useState(FEATURES[0].preview);
  const [connectorPoints, setConnectorPoints] = useState({ start: null, end: null });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const featureRefs = useRef([]);
  const previewRef = useRef(null);
  const containerRef = useRef(null);

  const calculateConnectorPoints = (featureElement) => {
    if (!featureElement || !previewRef.current || !containerRef.current) return null;

    return new Promise(resolve => {
      requestAnimationFrame(() => {
        const containerRect = containerRef.current.getBoundingClientRect();
        const featureRect = featureElement.getBoundingClientRect();
        const previewRect = previewRef.current.getBoundingClientRect();

        resolve({
          start: {
            x: featureRect.left + featureRect.width / 2 - containerRect.left,
            y: featureRect.top - containerRect.top + 5
          },
          end: {
            x: previewRect.left + previewRect.width / 2 - containerRect.left,
            y: previewRect.bottom - containerRect.top
          }
        });
      });
    });
  };

  const updateConnector = async () => {
    const activeIndex = FEATURES.findIndex(f => f.id === activeFeature.id);
    const activeElement = featureRefs.current[activeIndex];

    if (activeElement) {
      const points = await calculateConnectorPoints(activeElement);
      if (points) {
        setConnectorPoints(points);
      }
    }
  };

  // Initial load effect
  useEffect(() => {
    if (isInitialLoad) {
      const img = new Image();
      img.src = activeFeature.preview;

      img.onload = async () => {
        setTimeout(async () => {
          await updateConnector();
          setIsInitialLoad(false);
        }, 100);
      };
    }
    // eslint-disable-next-line
  }, [isInitialLoad, activeFeature.preview]);

  // Handle resize
  useEffect(() => {
    const handleResize = () => {
      updateConnector();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, [activeFeature]);

  // Handle feature changes
  useEffect(() => {
    if (!isInitialLoad) {
      setIsTransitioning(true);
      setIsLoading(true);

      // Update connector position immediately
      updateConnector();

      const img = new Image();
      img.src = activeFeature.preview;

      img.onload = () => {
        setTimeout(() => {
          setCurrentImage(activeFeature.preview);
          setIsTransitioning(false);
          setIsLoading(false);
        }, 300);
      };
    }
    // eslint-disable-next-line
  }, [activeFeature, isInitialLoad]);

  const handleFeatureClick = (feature) => {
    if (feature.id !== activeFeature.id) {
      setActiveFeature(feature);
    }
  };

  const getConnectorPath = () => {
    if (!connectorPoints.start || !connectorPoints.end) return '';

    const { start, end } = connectorPoints;
    const midY = (start.y + end.y) / 2;

    // Add horizontal offset for the curve
    const horizontalOffset = 50;

    // Determine if it's the middle feature
    const isMiddleFeature = Math.abs(start.x - end.x) < 20;

    // For middle feature, create an S-curve
    if (isMiddleFeature) {
      return `
        M ${start.x} ${start.y}
        C ${start.x - horizontalOffset} ${start.y + 3},
          ${end.x + horizontalOffset} ${end.y - 5},
          ${end.x} ${end.y}
      `;
    }

    // For other features, create a curved path
    return `
      M ${start.x} ${start.y}
      C ${start.x} ${midY - 50},
        ${end.x} ${midY + 50},
        ${end.x} ${end.y}
    `;
  };

  return (
    <section id='features' className={styles.featuresSection} ref={containerRef}>
      <div className={styles.tvStand}>
        <div className={styles.tvContainer}>
          <div className={styles.featurePreview} ref={previewRef}>
            <div className={`${styles.previewContainer} ${isTransitioning ? styles.transitioning : ''}`}>
              <img
                src={currentImage}
                alt={`${activeFeature.title} preview`}
                className={styles.previewMedia}
              />
            </div>
          </div>
        </div>
      </div>

      <svg className={styles.connectorSvg}>
        <defs>
          <linearGradient id="connectorGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#4a9eff" stopOpacity="1" />
            <stop offset="100%" stopColor="#4a9eff" stopOpacity="0.5" />
          </linearGradient>
          <filter id="connectorGlow">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
            <feColorMatrix in="blur" mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="glow" />
            <feComposite in="SourceGraphic" in2="glow" operator="over" />
          </filter>
        </defs>
        <path
          d={getConnectorPath()}
          className={`${styles.connector} ${isLoading ? styles.loading : ''}`}
          style={{ opacity: isInitialLoad ? 0 : 1 }}
        />
      </svg>

      <div className={styles.featuresContainer}>
        <div className={styles.pyramidContainer}>
          <div className={styles.features}>
            {FEATURES.map((feature, index) => (
              <div
                key={feature.id}
                ref={el => featureRefs.current[index] = el}
                className={`${styles.feature} ${activeFeature.id === feature.id ? styles.activeFeature : ''}`}
                onClick={() => handleFeatureClick(feature)}
              >
                <feature.icon className={styles.featureIcon} />
                <div className={styles.featureContent}>
                  <h3 className={styles.featureTitle}>{feature.title}</h3>
                  <p className={styles.featureDescription}>{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;