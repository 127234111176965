import React, { useEffect, useState } from 'react';
import { Menu, X } from 'lucide-react';
import styles from './Header.module.css';

const Header = ({ activeSection }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const controlHeader = () => {
            const currentScrollY = window.scrollY;

            // Show header when scrolling up or at the top
            if (currentScrollY < lastScrollY || currentScrollY < 100) {
                setIsVisible(true);
            }
            // Hide header when scrolling down and not at the top
            else if (currentScrollY > lastScrollY && currentScrollY > 100) {
                setIsVisible(false);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', controlHeader);

        return () => {
            window.removeEventListener('scroll', controlHeader);
        };
    }, [lastScrollY]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={`${styles.header} ${!isVisible ? styles.headerHidden : ''}`}>
            <div className={styles.headerContent}>
                <a href="#home" className={styles.logo}>
                    PresentPro
                </a>

                <button className={styles.menuButton} onClick={toggleMenu}>
                    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>

                <nav className={`${styles.nav} ${isMenuOpen ? styles.navOpen : ''}`}>
                    <ul className={styles.navList}>
                        <li>
                            <a
                                href="#home"
                                className={`${styles.navLink} ${activeSection === 'home' ? styles.active : ''}`}
                            >
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                href="#features"
                                className={`${styles.navLink} ${activeSection === 'features' ? styles.active : ''}`}
                            >
                                Features
                            </a>
                        </li>
                        <li>
                            <a
                                href="#pricing"
                                className={`${styles.navLink} ${activeSection === 'pricing' ? styles.active : ''}`}
                            >
                                Pricing
                            </a>
                        </li>
                        <li>
                            <a
                                href="#faq"
                                className={`${styles.navLink} ${activeSection === 'faq' ? styles.active : ''}`}
                            >
                                FAQ
                            </a>
                        </li>
                    </ul>
                    <div className={styles.authButtons}>
                        <a href="https://speech.devsaura.com" className={styles.signupButton}>
                            Go to App
                        </a>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Header;